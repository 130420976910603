import { useState, useEffect } from 'react';

export enum LetterStatus {
  Unknown = 0,
  Letter = 2,
  LetterAndPosition = 4,
  Disabled = 1,
}

export const isBrowser = typeof window !== "undefined";

export function computeGuessStatus(
  guess: string,
  correctWord: string
): Array<LetterStatus> {
  // normalize inputs
  guess = isBrowser ? guess.toLocaleUpperCase() : '';
  correctWord = isBrowser ? correctWord.toLocaleUpperCase() : ''

  let remainder = correctWord
  let status = new Array(5).fill(LetterStatus.Unknown)

  // NOTE: to ensure proper coloring of the letters, it is impossible
  // to first identify correctly located letters, otherwise an incorrectly
  // located letter could be orange, and the subsequent correctly located
  // letter could be grey.

  // FIRST PASS: find all letters properly located
  for (let i = 0; i < guess.length; i++) {
    const c = guess.charAt(i)

    if (c === correctWord.charAt(i)) {
      // remove the first occurrence (important to color repetitions accurately)
      remainder = remainder.replace(c, "")

      // record that this matches both letter and position
      status[i] = LetterStatus.LetterAndPosition
    }
  }

  // SECOND PASS: find letters incorrectly located
  for (let i = 0; i < guess.length; i++) {
    const c = guess.charAt(i)

    // skip properly positioned letters
    if (c === correctWord.charAt(i)) continue

    // EITHER: the letter is in the word
    if (remainder.includes(c)) {
      remainder = remainder.replace(c, "")

      // since we have located correctly positioned letters, we know this is incorrectly positioned
      status[i] = LetterStatus.Letter
    }
    // OR: there are no more occurrences of the letter to be found
    else {
      status[i] = LetterStatus.Disabled
    }
  }

  return status
}

export function isToday(dateValue: number) {
  const inputDate = new Date(dateValue);
  const today = new Date();

  return inputDate.getFullYear() === today.getFullYear() &&
    inputDate.getMonth() === today.getMonth() &&
    inputDate.getDate() === today.getDate();
}

export const currentAttemptDefault = {
  attempt: 0,
  letterPos: 0,
}

export const gameStateDefault =
{
  gameOver: false,
  guessedWord: false,
  timeLastPlayed: 0
}

export const boardDefault = [
  ["", "", "", "", ""],
  ["", "", "", "", ""],
  ["", "", "", "", ""],
  ["", "", "", "", ""],
  ["", "", "", "", ""],
  ["", "", "", "", ""],
]

export const boardStatusDefault = [
  [
    LetterStatus.Unknown,
    LetterStatus.Unknown,
    LetterStatus.Unknown,
    LetterStatus.Unknown,
    LetterStatus.Unknown,
  ],
  [
    LetterStatus.Unknown,
    LetterStatus.Unknown,
    LetterStatus.Unknown,
    LetterStatus.Unknown,
    LetterStatus.Unknown,
  ],
  [
    LetterStatus.Unknown,
    LetterStatus.Unknown,
    LetterStatus.Unknown,
    LetterStatus.Unknown,
    LetterStatus.Unknown,
  ],
  [
    LetterStatus.Unknown,
    LetterStatus.Unknown,
    LetterStatus.Unknown,
    LetterStatus.Unknown,
    LetterStatus.Unknown,
  ],
  [
    LetterStatus.Unknown,
    LetterStatus.Unknown,
    LetterStatus.Unknown,
    LetterStatus.Unknown,
    LetterStatus.Unknown,
  ],
  [
    LetterStatus.Unknown,
    LetterStatus.Unknown,
    LetterStatus.Unknown,
    LetterStatus.Unknown,
    LetterStatus.Unknown,
  ],
]

async function generateWordSetFromWordBank(
  wordBank: string
): Promise<{ wordSet: Set<string> }> {
  let wordSet: Set<string> = new Set()
  await fetch(wordBank)
    .then((response) => response.text())
    .then((result) => {
      const wordArr = result.indexOf('\r\n') !== -1 ? result.toUpperCase().split("\r\n") : result.toUpperCase().split("\n");
      wordSet = new Set(wordArr)
    })
  return { wordSet }
}

export async function generateWordSet(file: string): Promise<{ wordSet: Set<string> }> {
  let wordSet: Set<string> = new Set()
  let response = await generateWordSetFromWordBank('/pelit/sanapeli/' + file)
  wordSet = response.wordSet
  return { wordSet }
}

export function getRandomItemFromSet<X>(set: Set<X>) {
  const arr = Array.from(set.keys())
  const startDate = new Date("1 Feb 2022");

  const timeDiff = new Date().getTime() - startDate.getTime();
  const randomIndex = Math.floor(Math.abs(timeDiff / (1000 * 3600 * 24))) % arr.length;

  return arr[randomIndex]
}

export function copyResultsToClipboard(
  gameState: { gameOver: boolean; guessedWord: boolean; timeLastPlayed: number; },
  currAttempt: { attempt: number; letterPos: number; },
  boardStatus: LetterStatus[][], 
  language: string) {

  if (isBrowser) {
    // loop through boardStatus and create wordle results string
    let text = `Sanapeli ${language === 'en' ? 'englanti' : 'suomi'} ${gameState.guessedWord ? currAttempt.attempt : 'X'}/6\nkouluruoka.fi\n\n`;

    // use for loop to construct rows of icons from the boardStatus LetterStatus and append it to the text, if row is all unknown, skip it
    for(let i = 0; i < boardStatus.length; i++) {
      const row = boardStatus[i];
      if (!row.every((status: number) => status === LetterStatus.Unknown)) {
        text += row.map((status: number) => {
          switch (status) {
            case LetterStatus.LetterAndPosition:
              return '🟩';
            case LetterStatus.Letter:
              return '🟨';
            default:
              return '⬛';
          }
        }).join('') + '\n';
      }
    }

    navigator.clipboard.writeText(text);
  }
}

export function usePersistentState<Type>(
  key: string,
  initialState: Type | (() => Type),
  language: string,
  gameState: any = { gameState: false, guessedWord: false, timeLastPlayed: 0 }
): [Type, React.Dispatch<React.SetStateAction<Type>>] {
  const prefixedKey = `sanapeli_${language}_${key}`;

  // read key from local storage if not found use default value
  const [value, setValue] = useState<Type>(() => {

    let timeLastPlayed = gameState.timeLastPlayed;

    if (key === 'gameState') {
      let sv = isBrowser ? localStorage.getItem(prefixedKey) : null;
      if (sv) {
        timeLastPlayed = JSON.parse(sv).timeLastPlayed;
      }
    }

    const storedValue = (!isBrowser || !isToday(timeLastPlayed)) ? null : localStorage.getItem(prefixedKey);

    if (storedValue === null) {
      if (typeof initialState === 'function') {
        return (initialState as () => Type)();
      } else {
        return initialState;
      }
    } else {
      return JSON.parse(storedValue);
    }
  });

  // update local storage when value changes
  useEffect(() => {
    localStorage.setItem(prefixedKey, JSON.stringify(value));
  }, [value, prefixedKey]);

  return [value, setValue];
}

export default computeGuessStatus