import React, { useEffect } from "react"

import "./sanapeli/App.css"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { isBrowser } from "./sanapeli/helpers"
import flag_fi from "../../images/flag_fi.png"
import flag_uk from "../../images/flag_uk.png"

const GamesPage = () => {
    // generate set once (by empty deps)
    useEffect(() => {
        try {
            if (isBrowser) {
                const adsbygoogle = window.adsbygoogle || [];
                adsbygoogle.push({});
            }
        } catch (e) {
            console.error(e);
        }
    }, [])

    return (
        <Layout>
            <SEO title="Pelit" description="kouluruoka.fi opettavaiset pelit" />
            <h1 className='pageTitle'>Kouluruoka.fi pelit</h1>
            <div className="gameBorder">
                <h2>Sanapeli</h2>

                <div className="gameTitle">
                    <a href="/pelit/sanapeli/?lang=fi"><img src={flag_fi} alt="Suomen lippu" width={75} /><br/>suomeksi</a>
                </div>
                <div className="gameTitle">
                    <a href="/pelit/sanapeli/?lang=en"><img src={flag_uk} alt="Iso-Britannian lippu" width={75} /><br/>englanniksi</a>
                </div>
            </div>

            <div id='adbanner' className="adBannerParent" key="adbanner">
                <ins className="adBanner adsbygoogle"
                    data-ad-client="ca-pub-2668838512868610"
                    data-ad-slot="5745330552"
                    data-ad-format="horizontal,auto"
                    data-full-width-responsive="true"></ins>
            </div>
        </Layout>
    )
}

export default GamesPage
